.WhiteBtn {
  display: block;
  border: var(--main-color) 1px solid;
  background-color: white;
  border-radius: 20px;
  padding: 8px;
  color: var(--point-color);
  width: 150px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14px;
}

.WhiteBtn:hover {
  background-color: var(--main-color);
  color: white;
  border: var(--main-color) 1px solid;
}

.LinkBtn {
  animation: fontAnimation 1s infinite;
}

@keyframes fontAnimation {
  0% {
    transform: scale(1.09);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.09);
  }
}
