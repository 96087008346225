@import '../../App.css';

.Main {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow-y: auto;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;
}

.contentContainer {
  height: 100%;
  width: 320px;
  padding: 10px;
  gap: 20px;
  padding-top: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 10px;
}

.responseImg {
  font-size: 60px;
}

.itemInfo {
  padding: 20px 0;
  line-height: 1.4;
}

.title {
  font-size: 24px;
  line-height: 1.4;
  color: var(--point-color);
}

.createdAt {
  font-size: 12px;
  color: var(--point-color);
  text-align: right;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.answerer {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid var(--main-color);
  width: 100%;
  cursor: pointer;
}

.answerer:hover {
  background-color: var(--main-color);
  color: var(--point-color);
}

.answerer div {
  flex-grow: 1;
}

.answerer div:nth-child(2) {
  font-size: 12px;
  color: var(--point-color);
  text-align: right;
}

.pageBtns button {
  border: none;
  background-color: var(--main-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin: 3px;
}

.pageBtns .currentPage {
  background-color: var(--point-color);
}

.pageBtns .isEndBtn {
  background-color: lightgray;
}
