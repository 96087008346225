.DisplayAnswerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.noResponse {
  display: flex;
  flex-direction: column;
  height: 50vh;
  gap: 20px;
}

.noResponsetitle {
  font-size: 20px;
  text-align: center;
}

.noResponsecontent {
  display: flex;
  font-size: 100px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.haveResponse {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 15px;
}

.haveResponseImg {
  font-size: 60px;
}

.haveResponseTitle {
  font-size: 20px;
}

.listContainer {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectSortOrderWrapper {
  width: 100%;
  text-align: right;
}

.selectSortOrder {
  border: none;
  background-color: transparent;
  padding: 3px 0px;
  color: var(--point-color);
}

.haveResponseList {
  width: 90%;
  height: 100%;
}

.haveResponseList div {
  cursor: pointer;
  padding: 5px;
}

.correctAnswerer {
  color: var(--point-color);
  /* background-color: var(--main-color); */
  cursor: pointer;
  width: 168px;
}

.owner {
  color: black;
  cursor: pointer;
  width: 168px;
}

.notOwner {
  color: lightgray;
  cursor: pointer;
  width: 168px;
}

.listTable {
  border-bottom: 1px solid var(--main-color);
  padding: 8px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  align-items: center;
}

.chatIcon {
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}

.pageBtns button {
  border: none;
  background-color: var(--main-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin: 3px;
}

.pageBtns .currentPage {
  background-color: var(--point-color);
}

.pageBtns .isEndBtn {
  background-color: lightgray;
}

.commonBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading {
  text-align: center;
  font-size: 20px;
}
