@import '../../App.css';

.Welcome {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.Welcome .title {
  font-size: 20px;
  text-align: center;
  line-height: 1.2;
}

.Welcome .title :first-child {
  animation: fontAnimation 1s infinite;
  font-size: 40px;
}

.Welcome .section {
  line-height: 1.6;
  text-align: center;
}

.Welcome .section div {
  font-size: 18px;
  line-height: 1.4;
  color: var(--point-color);
  padding: 10px 0px;
  font-weight: bold;
  text-align: center;
}

.Welcome .nameInput {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
}

@keyframes fontAnimation {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
