@font-face {
  font-family: 'LeeSeoyun';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/LeeSeoyun.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'LeeSeoyun';
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-color: white;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --main-color: #ffd1d1;
  --point-color: #ff9494;
  --hover-color: #ff7979;
  --text-color: #424242;
  --border-color: #cccccc;
  --background-color: #fafafa;
  --receiveMessage-color: #ffe5a1;
  --sendMessage-color: #ffd1d1;
}
