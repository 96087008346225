@import '../App.css';

.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.content {
  position: relative;
  width: 320px;
  height: 90vh;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
