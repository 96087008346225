.Modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 280px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid var(--point-color);
  padding: 20px;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(224, 224, 224, 0.513);
}

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ModalBtns {
  display: flex;
  gap: 10px;
}

.okBtn,
.confirmBtn {
  width: 60px;
  height: 30px;
  border: none;
  border-radius: 20px;
  background-color: var(--point-color);
  color: white;
  cursor: pointer;
}

.cancelBtn {
  width: 60px;
  height: 30px;
  border: none;
  border: 1px solid var(--point-color);
  border-radius: 20px;
  background-color: white;
  color: var(--point-color);
  cursor: pointer;
}

.okBtn:hover,
.confirmBtn:hover {
  background-color: var(--hover-color);
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
  height: 60vh;
  width: 280px;
}

.answerer {
  font-size: 20px;
}

.qna div {
  overflow-wrap: break-word;
  padding: 3px 0;
}

.qna :nth-child(3n-2) {
  color: var(--point-color);
  font-size: 20px;
}

.qna :nth-child(3n) {
  padding-bottom: 15px;
}
