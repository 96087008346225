.ReceiveMessage {
  width: 80%;
  padding: 10px;
  margin-right: auto;
  box-sizing: border-box;
  text-align: left;
}

.rMessage {
  background-color: var(--receiveMessage-color);
  border-radius: 5px;
  word-wrap: break-word;
  overflow-y: auto;
  padding: 10px;
  margin: 5px 0;
}

.receiver,
.rTime {
  font-size: 12px;
}
