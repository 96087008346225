@import '../../App.css';

.WriteResponse {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progressBar {
  width: 90%;
  height: 20px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: var(--border-color);
}

.progress {
  width: 0px;
  height: 20px;
  border-radius: 20px;
  background-color: var(--point-color);
}

.QuestionList {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
  text-align: center;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 16px;
  line-height: 1.2;
}

.middle {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.questionContent {
  display: flex;
  flex-direction: column;
  gap: 50px;
  font-size: 20px;
  align-items: center;
  width: 100%;
}

.answer {
  width: 80%;
  word-break: break-all;
}

.questionContent p {
  color: var(--point-color);
}

.Btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.questionBtns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skipBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  border: none;
  background-color: transparent;
  color: var(--point-color);
  cursor: pointer;
  font-size: 16px;
}

.responseAnswerLength {
  font-size: 12px;
  padding-top: 10px;
  text-align: right;
}
