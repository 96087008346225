.Modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 315px;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid var(--point-color);
  padding: 20px;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(224, 224, 224, 0.513);
}

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ModalBtns {
  display: flex;
  gap: 10px;
}

.okBtn,
.confirmBtn {
  width: 60px;
  height: 30px;
  border: none;
  border-radius: 20px;
  background-color: var(--point-color);
  color: white;
  cursor: pointer;
}

.cancelBtn {
  width: 60px;
  height: 30px;
  border: none;
  border: 1px solid var(--point-color);
  border-radius: 20px;
  background-color: white;
  color: var(--point-color);
  cursor: pointer;
}

.okBtn:hover,
.confirmBtn:hover,
.eventBtn:hover,
.closeBtn:hover {
  background-color: var(--hover-color);
  color: white;
}

.message {
  word-break: break-all;
  white-space: pre-line;
  text-align: center;
  line-height: 1.2;
}

.EventModal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 350px;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid var(--point-color);
  padding: 20px;
}

.event {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.eventBtns {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.eventText {
  text-align: center;
}

.eventBtn,
.closeBtn {
  border: var(--point-color) 1px solid;
  background-color: white;
  border-radius: 20px;
  padding: 5px;
  color: var(--point-color);
  width: 200px;
  height: 30px;
  cursor: pointer;
}

@keyframes fontAnimation {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.noShowBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  border: none;
  background-color: transparent;
}
