/* Header.module.css */
.Header {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 5px;
  height: 35px;
  background-color: var(--main-color);
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
  cursor: pointer;
}

.history {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  left: 0;
  margin-left: 10px;
}

.title {
  cursor: pointer;
  position: relative;
}

.menu {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  right: 0;
  margin-right: 10px;
}

.noNewMessage {
  position: absolute;
  cursor: pointer;
  right: 40px;
  height: 25px;
  width: 25px;
}

.haveNewMessage {
  fill: var(--point-color);
  animation: alarmAnimation 0.5s infinite;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  flex-direction: column;
  background-color: white;
  color: var(--point-color);
  border: 1px solid rgb(230, 230, 230);
  z-index: 1;
  text-align: center;
}

.dropdown li {
  list-style: none;
  padding: 10px 0;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: rgb(248, 248, 248);
}

@keyframes alarmAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
