@import '../../App.css';

.Main {
  display: flex;
  justify-content: center;
  min-height: 101vh;
  overflow-y: auto;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;
}

.content {
  height: 100%;
  width: 320px;
  padding: 10px;
  gap: 20px;
  padding-top: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 20px;
  text-align: center;
  line-height: 1.4;
  width: 80%;
  padding: 30px 0px;
}

.title div:first-child {
  animation: fontAnimation 1s infinite;
  font-size: 50px;
  padding-bottom: 10px;
}

.historyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 80%;
  justify-content: center;
}

.history {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid var(--main-color);
  width: 100%;
  cursor: pointer;
}

.history:hover {
  background-color: var(--main-color);
  color: var(--point-color);
}

.history div {
  flex-grow: 1;
}

.history div:nth-child(2) {
  font-size: 12px;
  color: var(--point-color);
  text-align: right;
}

.description {
  font-size: 20px;
  color: var(--point-color);
  padding: 10px 0px;
}

@keyframes fontAnimation {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
