@import '../../App.css';

.WriteChallengeContainer {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.top {
  text-align: center;
  line-height: 1.6;
}

.top :first-child {
  font-size: 40px;
  animation: fontAnimation 0.8s infinite;
}

.top :nth-child(2) {
  font-size: 25px;
  color: var(--point-color);
}

.middle {
  display: flex;
  justify-content: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
}

@keyframes fontAnimation {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.challenge {
  width: 80%;
  text-align: center;
}

.challengeLength {
  font-size: 12px;
  padding-top: 10px;
  text-align: right;
}
