.DisplayAnswer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 30px;
}

.DisplayAnswerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  gap: 30px;
}

.answerer {
  width: 300px;
  padding: 0px 10px;
  text-align: center;
  font-size: 20px;
}

.answer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  width: 280px;
  padding: 0px 10px;
}

.answer div {
  overflow-wrap: break-word;
}

.answer :nth-child(3n-2) {
  color: var(--point-color);
  font-size: 20px;
}

.answer :nth-child(3n) {
  padding-bottom: 15px;
}
